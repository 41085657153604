import { EmptyState, TextStyle, Image, Heading } from "@shopify/polaris";
import router from "next/router";
import { useRef } from "react";
import ApplicationFrame from "../components/frame";
import useTranslator from "../hooks/i18n.hook";

export default function Custom404() {
  const skipToContentRef = useRef(null);
  const i18n = useTranslator("404")

  return <ApplicationFrame skipToContentRef={skipToContentRef}>
    <EmptyState
      action={{ content: i18n.translate("404.actions.primary"), url: "/" }}
      secondaryAction={{ content: i18n.translate("404.actions.secondary"), onAction: () => router.back() }}
      image=""
      fullWidth
    >
      <Image
        source="./error.png"
        alt="HappyColis"
        style={{ width: "450px" }}
      />
      <br />
      <br />

      <div style={{ fontSize: "20px" }}>{i18n.translate("404.title")}</div>
      <br />
      <TextStyle variation="subdued">{i18n.translate("404.paragraph")}</TextStyle>
    </EmptyState>
  </ApplicationFrame>

}